<template>
  <v-form autocomplete="off" @submit.prevent="save()" ref="form">
    <v-text-field
      :label="`${$t('resellers.fields.name.description')} *`"
      :placeholder="$t('resellers.fields.name.title')"
      v-model="form.name"
      @input="delayTouch($v.form.name)"
      @blur="$v.form.name.$touch"
      :error-messages="nameErrors"
    ></v-text-field>
    <v-text-field
      :label="$t('resellers.fields.location.description')"
      :placeholder="$t('resellers.fields.location.title')"
      v-model="form.localisation"
      @input="delayTouch($v.form.localisation)"
      @blur="$v.form.localisation.$touch"
      :error-messages="locationErrors"
    ></v-text-field>
    <v-text-field
      :label="$t('resellers.fields.wholesale.description')"
      :placeholder="$t('resellers.fields.wholesale.title')"
      v-model="form.depot"
      @input="delayTouch($v.form.depot)"
      @blur="$v.form.depot.$touch"
      :error-messages="wholesaleErrors"
    ></v-text-field>
    <v-text-field
      :label="$t('resellers.fields.deliveryTeam.description')"
      :placeholder="$t('resellers.fields.deliveryTeam.title')"
      v-model="form.assignedDepositSeller"
      @input="delayTouch($v.form.assignedDepositSeller)"
      @blur="$v.form.assignedDepositSeller.$touch"
      :error-messages="deliveryTeamErrors"
    ></v-text-field>
    <v-checkbox
      v-model="form.enabled"
      color="secondary"
      :label="$t('resellers.fields.enabled.title')"
    ></v-checkbox>
    <v-text-field
      :label="`${$t('resellers.fields.identifier.description')}`"
      :placeholder="$t('resellers.fields.identifier.title')"
      v-model="form.identifier"
      :hint="$t('resellers.fields.identifier.help')"
      @input="delayTouch($v.form.identifier)"
      @blur="$v.form.identifier.$touch"
      :error-messages="identifierErrors"
      :loading="loading.identifier"
      maxlength="8"
    ></v-text-field>
    <v-text-field
      :label="`${$t('resellers.fields.pin.description')}`"
      :placeholder="$t('resellers.fields.pin.title')"
      v-model="form.pin"
      :hint="$t('resellers.fields.pin.help')"
      @input="delayTouch($v.form.pin)"
      @blur="$v.form.pin.$touch"
      :error-messages="pinErrors"
      maxlength="6"
    ></v-text-field>
    <div class="mt-5">
      <span class="font-weight-bold third--text">{{
        $t("resellers.fields.owner.title")
      }}</span>
    </div>
    <v-autocomplete
      :items="items.users"
      :label="$t('resellers.fields.owner.subtitle')"
      :placeholder="$t('resellers.fields.owner.description')"
      v-model="form.owner"
      :search-input.sync="search.user"
      item-value="id"
      item-text="username"
      :loading="loading.user"
      clearable
      hide-no-data
    ></v-autocomplete>
    <div class="d-flex align-center justify-end mt-5">
      <v-btn type="submit" :loading="loading.save" color="secondary">
        <span class="black--text">
          <span v-if="!reseller.id">{{ $t("btn.add") }}</span>
          <span v-else>{{ $t("btn.edit") }}</span>
        </span>
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import FormDelayTouchMixin from "./../../mixins/commons/form-delay-touch";
import {
  required,
  helpers,
  maxLength,
} from "vuelidate/lib/validators";
import { mapActions } from "vuex";

const defaultForm = {
  name: null,
  identifier: null,
  localisation: null,
  owner: null,
  pin: null,
  enabled: true,
  assignedDepositSeller: null,
  depot: null
};

export default {
  mixins: [FormDelayTouchMixin],
  created() {
    this.initForm();
  },
  props: {
    reseller: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    items: {
      resellers: [],
      users: [],
    },
    loading: {
      identifier: false,
      user: false,
      save: false,
    },
    search: {
      reseller: null,
      user: null,
    },
    form: Object.assign({}, defaultForm),
  }),
  validations() {
    const validations = {
      form: {
        name: {
          required,
          maxLength: maxLength(100),
        },
        localisation: {
          maxLength: maxLength(100),
        },
        identifier: {
          regex: helpers.regex("regex", /^GC[0-9]+$/),
          isUnique: value => !helpers.req(value) || (async () => {
            if (!value) return true;
            if (value.length < 2) return true;
            
            if (this.reseller.identifier == value)
              return true;

            try {
              this.loading.identifier = true;
              await this.request({
                url: "api/v1/resellers/identifier/exists",
                method: "post",
                data: { identifier: this.form.identifier },
              });
              this.loading.identifier = false;
              return false;
            } catch (error) {
              this.loading.identifier = false;
              // empty
              if (error && error.response) {
                if(error.response.status === 400) return true;
                if (error.response.status === 404) return true;
              }
              return false;
            }
          })(),
        },
        pin: {
          regex: helpers.regex("regex", /^[0-9]{5}$/i),
        },
        depot: {
          maxLength: maxLength(50)
        },
        assignedDepositSeller: {
          maxLength: maxLength(50)
        }
      },
    };
    return validations;
  },
  methods: {
    ...mapActions({ notify: "notification/notify", request: "request" }),
    initForm() {
      if (this.reseller.id) {
        if (this.reseller.owner) this.initUserItems(this.reseller.owner);

        for (const prop in this.form) {
          this.form[prop] = this.reseller[prop];
        }
        this.$nextTick(function() {
          this.$v.form.identifier.$touch();
        })
      }
    },
    async save() {
      let url;
      let method;
      this.notify({ status: false });

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.notify({ message: this.$t("errors.bad_request") });
        return;
      }

      if (this.reseller.id) {
        url = `api/v1/resellers/${this.reseller.id}`;
        method = "put";
      } else {
        url = "api/v1/resellers";
        method = "post";
      }

      this.loading.save = true;

      try {
        // eslint-disable-next-line no-unused-vars
        const { type, ...data } = this.form;
        await this.request({
          url: url,
          method: method,
          data: data,
          messages: {
            400: true,
            201: this.$t("resellers.add.success"),
            200: this.$t("resellers.edit.success"),
            500: true,
          },
        });

        if (!this.reseller.id) {
          this.resetForm();
        }
      } catch (error) {
        // empty
      }
      this.loading.save = false;
    },
    resetForm() {
      this.form = Object.assign({}, defaultForm);
      this.$v.form.$reset();
    },
    async initUserItems(id) {
      this.loading.user = true;
      try {
        const response = await this.request({
          url: "api/v1/users",
          params: { id: id },
        });
        this.items.users = response.data.items;
      } catch (error) {
        // empty
      }
      this.loading.user = false;
    },
  },
  watch: {
    async "search.reseller"(val) {
      if (!val) return;
      if (this.loading.reseller) return;

      this.loading.reseller = true;
      try {
        const response = await this.request({
          url: "api/v1/resellers",
          params: { name: val, type: "POINT_OF_SALE" },
        });
        this.items.resellers = response.data.items;
      } catch (error) {
        // empty
      }
      this.loading.reseller = false;
    },
    async "search.user"(val) {
      if (!val) return;
      if (this.loading.user) return;

      this.loading.user = true;
      try {
        const response = await this.request({
          url: "api/v1/users",
          params: { username: val, role: "ROLE_SELLER" },
        });
        this.items.users = response.data.items;
      } catch (error) {
        // empty
      }
      this.loading.user = false;
    },
  },
  computed: {
    nameErrors() {
      const errors = [];

      if (!this.$v.form.name.$dirty) return errors;

      !this.$v.form.name.required &&
        errors.push(this.$t("resellers.fields.name.required"));
      !this.$v.form.name.maxLength &&
        errors.push(
          this.$t("resellers.fields.name.maxLength", {
            max: this.$v.form.name.$params.maxLength.max,
          })
        );

      return errors;
    },
    locationErrors() {
      const errors = [];

      if (!this.$v.form.localisation.$dirty) return errors;
      
      !this.$v.form.localisation.maxLength &&
        errors.push(
          this.$t("resellers.fields.location.maxLength", {
            max: this.$v.form.localisation.$params.maxLength.max,
          })
        );

      return errors;
    },
    identifierErrors() {
      const errors = [];
      if (!this.$v.form.identifier.$dirty) return errors;
      (!this.$v.form.identifier.isUnique && !this.loading.identifier) &&
        errors.push(this.$t("resellers.fields.identifier.already_exist"));
      !this.$v.form.identifier.regex &&
        errors.push(this.$t("resellers.fields.identifier.invalid"));

      return errors;
    },
    pinErrors() {
      const errors = [];
      if (!this.$v.form.pin.$dirty) return errors;

      !this.$v.form.pin.regex &&
        errors.push(this.$t("resellers.fields.pin.invalid"));

      return errors;
    },
    wholesaleErrors() {
      const errors = [];

      if (!this.$v.form.depot.$dirty) return errors;

      !this.$v.form.depot.maxLength &&
        errors.push(
          this.$t("resellers.fields.wholesale.maxLength", {
            max: this.$v.form.depot.$params.maxLength.max,
          })
        );

      return errors;
    },
    deliveryTeamErrors() {
      const errors = [];

      if (!this.$v.form.assignedDepositSeller.$dirty) return errors;

      !this.$v.form.assignedDepositSeller.maxLength &&
        errors.push(
          this.$t("resellers.fields.deliveryTeam.maxLength", {
            max: this.$v.form.assignedDepositSeller.$params.maxLength.max,
          })
        );

      return errors;
    },
  },
};
</script>